import React from 'react';
import { routes } from "./routes";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './fontawesome';
import Main from "./components/Main/Main";

const App = () => {
  return (
      <Main/>
//     <Router>
//       <Switch>
//         {routes.map((route, index) => {
//           return (
//             <Route
//               key={index}
//               path={route.path}
//               exact={route.exact}
//               component={ () => {
//                 return (
//                   <route.layout>
//                     <route.component />
//                   </route.layout>
//                 )
//               }}
//             />
//           );
//         })}
//       </Switch>
//     </Router>

  );
};

export default App;
