import React from 'react';
import {Carousel} from '3d-react-carousal';
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col, DropdownItem, DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import {NavLink} from "react-router-dom";

export default function Main() {

    let slides = [
        <img  src="https://picsum.photos/800/300/?random" alt="1" />,
        <img  src="https://picsum.photos/800/301/?random" alt="2" />  ,
        <img  src="https://picsum.photos/800/302/?random" alt="3" />  ,
        <img  src="https://picsum.photos/800/303/?random" alt="4" />  ,
        <img src="https://picsum.photos/800/304/?random" alt="5" />
    ];

    return (
        <div>
            <h1 className={"display-1"}>Virya Health</h1>

            <h3><small className="text-monospace">Next Generation Healthcare Solutions</small>
                </h3>


            <Carousel slides={slides} autoplay={true} interval={5000}/>
        </div>
    );
}